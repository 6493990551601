import React, {useState, useMemo, useEffect, useRef} from 'react'

import Image from 'next/image'
import star_white from '../public/img/star-white.svg'
import arrow_header from '../public/img/arrow-header.svg'
import star from '../public/img/star.svg'
import arrow_right_white from '../public/img/arrow-right-white.svg'
import Dropdown from './Dropdown'
import HeaderDropdown from './HeaderDropdown'
import star_active from '../public/img/star-active.svg'
import calculator from '../public/img/calculator-ico.svg'
import ScoreSwitcher from './ScoreSwitcher'
import { useTranslation } from 'next-i18next'
import HeaderDropdownQual from './HeaderDropdownQual'
import VirtualBookmaker from './VirtualBookmaker'
import withFavorite from '../hoc/favorite';
import clsx from 'clsx';
import moment from 'moment';
import {Odds, OddsFetched} from '../api';
import {BookmakerLinkRedirect} from './BookmakerLinkRedirect';
import { Logo } from './logo'

type Props = {
    odds: OddsFetched,
    oddsTypeOutside: string,
    isBlockFullSize?: boolean,

    matchId: number,
    matchDateStart: number,
    isMatchLive: boolean,
    teamOneTitle: string,
    teamTwoTitle: string,

    onChangeOddType: Function
    onChangePeriod: Function
    onChangeHandicap: Function
    onChangeType: Function
    onClickFavorite?: Function
    loading?: boolean
    containerRef?: any,
    favorites?: any,
}

type PropsWithFavorite = {
    toggleFavorite: Function,
    favorites: any,
} & Props

type FilterType = 'odd' | 'payout'
type Filter = {
    type: FilterType,
    sort: 'asc' | 'desc',
    index: number,
}

const OddsBlock = (
    {
        odds,
        oddsTypeOutside,
        isBlockFullSize,
        matchId,
        matchDateStart,
        teamOneTitle,
        teamTwoTitle,
        isMatchLive,
        onChangeOddType,
        loading,
        onChangePeriod,
        onChangeType,
        onChangeHandicap,
        favorites,
        toggleFavorite,
        containerRef,
    }: PropsWithFavorite,
) => {
    const { t } = useTranslation('common')

    const isMatchEnded =  !isMatchLive && moment.utc(new Date()) > moment(matchDateStart * 1000)

    const [open, setOpen] = useState(false)
    const [type, setType] = useState(isMatchLive ? 'live' : 'regular')
    const [oddType, setOddType] = useState(odds.odd_type)
    const [oddPeriod, setOddPeriod] = useState(String(odds.period))
    const [handicap, setHandicap] = useState(String(odds.handicap))
    const [isLoadingOnTypeChange, setIsLoadingOnTypeChange] = useState(false)
    const [filter, setFilter] = useState<Filter | null>(null)

    /**  */

    //Show loader only on type change
    const refTypePrev = useRef(null)
    const refOddTypePrev = useRef(null)
    const refPeriodPrev = useRef(null)
    const refHandicapPrev = useRef(null)
    useEffect(() => {
        refTypePrev.current = type
        refOddTypePrev.current = oddType
        refPeriodPrev.current = oddPeriod
        refHandicapPrev.current = handicap
        setIsLoadingOnTypeChange(true)
    }, [type, oddType, oddPeriod, handicap]);

    useEffect(() => {
        if (isLoadingOnTypeChange && !loading) setIsLoadingOnTypeChange(false)
    }, [loading, isLoadingOnTypeChange]);

    /**  */

    const showVirtualBookmakerBlock = odds.maker.find((i) => !!i.value)

    // if change odd_type
    useEffect(() => {
        setHandicap(String(odds.handicap))
        setOddType(odds.odd_type)
        // switch to default
        setOddPeriod(String(odds.period))
        setFilter(null)
    }, [odds.odd_type])

    useEffect(() => {
        setHandicap(String(odds.handicap))
        setFilter(null)
    }, [odds.period])

    useEffect(() => {
        setHandicap(String(odds.handicap))
        setFilter(null)
    }, [odds.period])

    // if change match_id
    useEffect(() => {
        setType(isMatchLive ? 'live' : 'regular')
        setOpen(false)
        setFilter(null)
    }, [matchId])

    useEffect(() => {
        if (type !== oddsTypeOutside) {
            onChangeType(type)
        }
    }, []);

    /**  */

    const oddTypes = odds.odds_types.map(({type, title}) => ({
        value: type,
        title: title,
    }))

    //@todo
    function compare(a: Odds, b: Odds) {
        if (filter.type == 'odd') {
            if (
                parseFloat(a.odds[filter.index].display) >
                parseFloat(b.odds[filter.index].display)
            )
                return filter.sort === 'asc' ? -1 : 1 // если первое значение больше второго
            if (
                parseFloat(a.odds[filter.index].display) ==
                parseFloat(b.odds[filter.index].display)
            )
                return 0 // если равны
            if (
                parseFloat(a.odds[filter.index].display) <
                parseFloat(b.odds[filter.index].display)
            )
                return filter.sort === 'asc' ? 1 : -1 // если первое значение меньше второго
        } else {
            if (a.payout > b.payout) return filter.sort === 'asc' ? -1 : 1 // если первое значение больше второго
            if (a.payout == b.payout) return 0 // если равны
            if (a.payout < b.payout) return filter.sort === 'asc' ? 1 : -1 // если первое значение меньше второго
        }
    }
    const oddsPrepared = useMemo(() => {
        let odds_copy = (odds?.odds ?? []).slice()

        if (filter) odds_copy = odds_copy.sort(compare)

        return open
            ? odds_copy
            : odds_copy.slice(0, odds_copy.length == 9 ? 9 : 8)
    }, [odds, open, filter])

    const periods = odds.periods.map(({period, title}) => ({
        value: String(period),
        title: title,
    }))

    const handicaps = odds.handicaps.map((value) => ({
        value: String(value),
        title: String(value),
    }))

    function toggleFilter(type: FilterType, index: number) {
        if (filter?.index == index) {
            setFilter({
                type,
                sort: filter.sort === 'asc' ? 'desc' : 'asc',
                index,
            })
            return;
        }

        setFilter({ index, sort: 'asc', type })
    }

    function _onChangeOddType(e: any) {
        setOddType(e.value)
        onChangeOddType(e.value)
    }

    function _onChangePeriod(e: any) {
        setOddPeriod(e.value)
        onChangePeriod(e.value)
    }

    function _onChangeHandicap(e: any) {
        setHandicap(e.value)
        onChangeHandicap(e.value)
    }

    function _onChangeType(type: string) {
        setType(type)
        onChangeType(type)
    }

    function _onClickRating(e: any, id: number) {
        e.preventDefault()
        e.nativeEvent.stopImmediatePropagation()
        toggleFavorite('bookmaker', id)
    }

    return (
        <div className="sidebar__wrapper-block" ref={containerRef}>
            <div className={`block rating-list surebet ${isBlockFullSize ? 'block--full' : ''}`}>
                <div className="rating-list__header">
                    <h5 className="rating-list__title">
                        {t('match_odds.title')}
                    </h5>
                    {isMatchLive && (
                        <div className="flex flex-align grid grid--4">
                            <button
                                className={clsx(
                                    'button button--filter-content button--filter-content-odds',
                                    type == 'live' && 'button--filter-content-odds-active',
                                )}
                                onClick={() => _onChangeType('live')}
                            >
                                <span>{t('match_odds.live')}</span>
                            </button>
                            <button
                                className={clsx(
                                    'button button--filter-content button--filter-content-odds',
                                    type == 'regular' && 'button--filter-content-odds-active',
                                )}
                                onClick={() => _onChangeType('regular')}
                            >
                                <span>{t('match_odds.pre_match')}</span>
                            </button>
                        </div>
                    )}
                </div>

                {odds?.odds_types.length > 0 && (
                    <div className="rating-list__block-use flex flex-align flex-justify grid grid--8 ">
                        <Dropdown
                            value={oddType}
                            className={'full-width dropdown-matches--odds'}
                            options={oddTypes}
                            optionsSub={handicaps}
                            valueSub={handicap}
                            onChange={_onChangeOddType}
                            onChangeSub={_onChangeHandicap}
                            header={<HeaderDropdown className={'full-width'} />}
                        />
                        <Dropdown
                            value={oddPeriod}
                            options={periods}
                            className={'full-width dropdown-matches--odds'}
                            onChange={_onChangePeriod}
                            disabled={oddType === 'qual'}
                            header={
                                oddType === 'qual' ? (
                                    <HeaderDropdownQual
                                        className={'full-width'}
                                    />
                                ) : (
                                    <HeaderDropdown className={'full-width'} />
                                )
                            }
                        />
                    </div>
                )}

                {oddType == 'score' && (
                    <ScoreSwitcher
                        team1={teamOneTitle}
                        team2={teamTwoTitle}
                        onChange={_onChangeHandicap}
                    />
                )}
                {odds?.odds_types.length > 0 && (
                    <div className="rating-list__subheader rating-list__subheader--odds">
                        <span className="rating-list__title-subheader">
                            {t('global.bookies')}
                        </span>
                        <div className="flex flex-align grid grid--16">
                            <div className="flex flex-align grid grid--4">
                                {odds?.odds_selects?.map(
                                    (bet: any, indexOdd: number) => (
                                        <div
                                            onClick={() => toggleFilter('odd', indexOdd)}
                                            key={`sub-header-${indexOdd}`}
                                            className="rating-list__col-header"
                                        >
                                            <span
                                                className={
                                                    filter &&
                                                        filter.index == indexOdd
                                                        ? 'highlight'
                                                        : ''
                                                }
                                            >
                                                {bet.odd_select_display}
                                            </span>
                                            <Image
                                                src={arrow_header}
                                                alt="Picture of the author"
                                                className={`rating-list__filter-ico ${filter &&
                                                    filter.index == indexOdd
                                                    ? filter.sort == 'asc'
                                                        ? 'rating-list__filter-ico--asc'
                                                        : 'rating-list__filter-ico--desc'
                                                    : ''
                                                }`}
                                                height={12}
                                                width={12}
                                            />
                                        </div>
                                    )
                                )}
                            </div>
                            <div
                                className="rating-list__col-header rating-list__col-header--last"
                                onClick={() => toggleFilter('payout', null)}
                            >
                                <span
                                    className={
                                        filter && filter.type == 'payout'
                                            ? 'highlight'
                                            : ''
                                    }
                                >
                                    {t('match_odds.payout')}
                                </span>
                                <Image
                                    src={arrow_header}
                                    alt="Picture of the author"
                                    className={`rating-list__filter-ico ${filter && filter.type == 'payout'
                                        ? filter.sort == 'asc'
                                            ? 'rating-list__filter-ico--asc'
                                            : 'rating-list__filter-ico--desc'
                                        : ''
                                    }`}
                                    height={12}
                                    width={12}
                                />
                                <div className="match-events__tooltip match-events__tooltip--payout match-events__tooltip--payout-top">
                                    <h5 className="match-events__tooltip-title match-events__tooltip-title--lineups">
                                        {t('match_odds.payout_desc')}
                                    </h5>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                <div
                    className={`rating-list__content rating-list__content--grid ${isMatchEnded || (isMatchLive && type == 'regular')
                        ? 'odds-ended'
                        : ''
                    }`}
                >
                    {(isLoadingOnTypeChange && loading) ? (
                        <div className={'preloader preloader--matchday'}>
                            <div className="">
                                <div className="preloader__block-sidebar-content grid grid--4">
                                    <div className="preloader__block-sidebar preloader__block-sidebar--row"></div>
                                    <div className="preloader__block-sidebar preloader__block-sidebar--row"></div>
                                    <div className="preloader__block-sidebar preloader__block-sidebar--row"></div>
                                    <div className="preloader__block-sidebar preloader__block-sidebar--row"></div>
                                    <div className="preloader__block-sidebar preloader__block-sidebar--row"></div>
                                    <div className="preloader__block-sidebar preloader__block-sidebar--row"></div>
                                    <div className="preloader__block-sidebar preloader__block-sidebar--row"></div>
                                    <div className="preloader__block-sidebar preloader__block-sidebar--row"></div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <>
                            {oddsPrepared?.length == 0 && (
                                <div className="no-content no-content--odds">
                                    <Image
                                        src={calculator}
                                        alt="calculator"
                                        width={32}
                                        height={32}
                                    />
                                    {
                                        <p className="no-content__title">
                                            {t('match_odds.odds_not_yet')}
                                        </p>
                                    }
                                </div>
                            )}
                            {oddsPrepared?.map((odd: any, index: number) => (
                                <div
                                    key={`odds-${index}`}
                                    className="rating-list__link"
                                >
                                    <div
                                        className={`rating-list__row rating-list__row--odds ${isMatchEnded ||
                                            (isMatchLive && type == 'regular')
                                            ? 'rating-list__row--ended'
                                            : ''
                                        }`}
                                    >
                                        <div className="flex flex-align grid grid--8 flex-justify full-width">
                                            <div className="flex flex-align grid grid--8">
                                                {(isMatchLive && type == 'regular') ? (
                                                    <div
                                                        className="bookmaker-badge"
                                                        style={{
                                                            backgroundColor: `#${odd.bookmaker?.color}`,
                                                        }}
                                                    >
                                                        <Logo
                                                            type='bookmaker_original'
                                                            logoId={odd.bookmaker.logo_id}
                                                            size={72}
                                                            height={24}
                                                        />
                                                    </div>
                                                ) : (
                                                    <BookmakerLinkRedirect
                                                        bookmakerId={odd?.bookmaker.id}
                                                        bookmakerLogoId={odd?.bookmaker.logo_id}
                                                        bookmakerColor={odd?.bookmaker.color}
                                                    >
                                                        <div
                                                            className="bookmaker-badge"
                                                            style={{
                                                                backgroundColor: `#${odd?.bookmaker?.color}`,
                                                            }}
                                                        >
                                                            <Logo
                                                                type='bookmaker_original'
                                                                logoId={odd.bookmaker.logo_id}
                                                                size={72}
                                                                height={24}
                                                            />
                                                        </div>
                                                    </BookmakerLinkRedirect>
                                                )}

                                                <div className="flex flex-align grid grid--4">
                                                    <button
                                                        className={`button button--view button--view-sidebar button--rating-book ${favorites.bookmaker.indexOf(
                                                            odd.bookmaker.id
                                                        ) !== -1
                                                            ? 'button--active-favorite'
                                                            : ''
                                                        }`}
                                                        onClick={(e) =>
                                                            _onClickRating(
                                                                e,
                                                                odd.bookmaker.id
                                                            )
                                                        }
                                                    >
                                                        <Image
                                                            src={star_white}
                                                            className="button__ico button__ico--view-white button__ico--abs"
                                                            alt="star"
                                                            priority={true}
                                                            width={16}
                                                            height={16}
                                                        />
                                                        <Image
                                                            src={
                                                                favorites.bookmaker.indexOf(
                                                                    odd
                                                                        .bookmaker
                                                                        .id
                                                                ) !== -1
                                                                    ? star_active
                                                                    : star
                                                            }
                                                            className="button__ico button__ico--view button__ico--abs button__ico--odd-bookmaker"
                                                            alt="star"
                                                            priority={true}
                                                            width={16}
                                                            height={16}
                                                        />
                                                    </button>
                                                    <span className="rating-list__price rating-list__price--odd">
                                                        {odd.bookmaker.rating.toFixed(
                                                            1
                                                        )}
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="flex flex-align grid grid--16">
                                                <div className="flex flex-align grid grid--4">
                                                    {odds?.odds_selects?.map(
                                                        (
                                                            bet: any,
                                                            indexOdd: number
                                                        ) =>
                                                            odd?.betslip &&
                                                                (!isMatchEnded ||
                                                                    (isMatchLive &&
                                                                        type !==
                                                                        'regular')) ? (
                                                                            <BookmakerLinkRedirect
                                                                        key={indexOdd}
                                                                        bookmakerId={odd.bookmaker.id}
                                                                        bookmakerLogoId={odd.bookmaker.logo_id}
                                                                        bookmakerColor={odd.bookmaker.color}
                                                                        matchId={matchId}
                                                                        oddType={oddType}
                                                                        oddSelect={bet?.odd_select}
                                                                        period={String(odds?.period)}
                                                                        handicap={Number(odds?.handicap)}
                                                                        className={`bet-wrapper bet-wrapper--odds ${!odd?.betslip ||
                                                                        (isMatchLive &&
                                                                            type ==
                                                                            'regular')
                                                                            ? 'bet-wrapper--no-betslip'
                                                                            : ''
                                                                        } ${odd
                                                                            ?.odds[
                                                                                indexOdd
                                                                            ]?.best
                                                                            ? 'bet-wrapper--best-odds'
                                                                            : ''
                                                                        }`}
                                                                    >
                                                                        <span
                                                                                    className={`bet-title ${!odd?.odds
                                                                                ? 'bet-title--gray'
                                                                                : ''
                                                                            }`}
                                                                        >
                                                                                    {odd?.odds
                                                                                ? odd
                                                                                    ?.odds[
                                                                                        indexOdd
                                                                                    ]
                                                                                    ?.display
                                                                                : '–'}
                                                                                </span>
                                                                        {!odd?.betslip &&
                                                                        !isMatchEnded && (
                                                                            <div className="tooltip tooltip--odds">
                                                                                <p>
                                                                                    {t(
                                                                                        'match_odds.no_link'
                                                                                    )}
                                                                                </p>
                                                                            </div>
                                                                        )}
                                                                    </BookmakerLinkRedirect>
                                                                ) : (
                                                                    <div
                                                                        key={`odds-${index}-${indexOdd}`}
                                                                        className={`bet-wrapper bet-wrapper--odds ${!odd?.betslip ||
                                                                        (isMatchLive &&
                                                                            type ==
                                                                            'regular')
                                                                            ? 'bet-wrapper--no-betslip'
                                                                            : ''
                                                                        } ${odd
                                                                            ?.odds[
                                                                                indexOdd
                                                                            ]?.best
                                                                            ? 'bet-wrapper--best-odds'
                                                                            : ''
                                                                        }`}
                                                                    >
                                                                        <span
                                                                            className={`bet-title ${!odd?.odds
                                                                                ? 'bet-title--gray'
                                                                                : ''
                                                                            }`}
                                                                        >
                                                                            {odd?.odds
                                                                                ? odd
                                                                                    ?.odds[
                                                                                        indexOdd
                                                                                    ]
                                                                                    ?.display
                                                                                : '–'}
                                                                        </span>
                                                                        {!odd?.betslip &&
                                                                        !isMatchEnded && (
                                                                            <div className="tooltip tooltip--odds">
                                                                                <p>
                                                                                    {t(
                                                                                        'match_odds.no_link'
                                                                                    )}
                                                                                </p>
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                )
                                                    )}
                                                </div>
                                                <div className="rating-list__col-header--last">
                                                    <span className="rating-list__row-subtitle">
                                                        {odd.payout.toFixed(1)}%
                                                    </span>
                                                    <div className="match-events__tooltip match-events__tooltip--payout">
                                                        <h5 className="match-events__tooltip-title match-events__tooltip-title--lineups">
                                                            {t(
                                                                'match_odds.payout_desc'
                                                            )}
                                                        </h5>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </>
                    )}
                </div>

                <div className="rating-list__footer rating-list__footer--odds">
                    {!open && odds?.odds.length > 9 && (
                        <button
                            className="button button--more-odds grid grid--4"
                            onClick={() => setOpen(true)}
                        >
                            <span className="button__text button__text--show-all">
                                {t('match_odds.bookies_more')}
                            </span>
                            <Image
                                src={arrow_right_white}
                                alt="video"
                                width={14}
                                height={14}
                            />
                        </button>
                    )}
                </div>

                {showVirtualBookmakerBlock ? (
                    <VirtualBookmaker full={isBlockFullSize} odds={odds} />
                ) : null}
            </div>
        </div>
    )
}

const OddsBlockWithFav = withFavorite<any>(OddsBlock)

const component = React.forwardRef((props: Props, ref) => (
    <OddsBlockWithFav
        {...props}
        containerRef={ref}
    />
))

component.displayName = 'OddsBlock'

export default component
