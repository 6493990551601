import {useGetCompetitionStandingsQuery} from '../../../../api/competitionsApi';
import {useCustomTranslation} from '../../../../hooks/useCustomTranslation';
import styles from './LeagueStandings.module.scss'
import clsx from 'clsx';
import {Tooltip} from '../../../../components/Tooltip';
import {Logo} from '../../../../components/logo';
import Link from 'next/link';
import {TeamName} from '../../../../components/team';
import {CompetitionMenuIndex, MatchResultCode} from '../../../../api';
import {useContainerWidth} from '../../../../hooks';
import {LoaderTemp} from '../../../../components/LoaderTemp';
import { Fragment } from 'react'
import st from './LeaguePlayoff.module.scss';
import Image from 'next/image';
import full_screen from '../../../../public/img/full-screen.svg';
import { StandingsLegend, StandingsMatchesCollapsible } from '../parts'

type Props = {
    competitionId: number,
    season: string,
    locale: string,
    changePageTab?: (index: CompetitionMenuIndex) => void,
    isInSidebar?: boolean,
}

// @todo Duplicates from MatchStandings
/** Standings block, display multiple tables and related matches */
export const LeagueStandings = ({
    competitionId,
    season,
    locale,
    isInSidebar,
    changePageTab,
}: Props) => {
    const {
        containerRef,
        blockWidth,
    } = useContainerWidth()

    const {data, isFetching} = useGetCompetitionStandingsQuery({
        competitionId,
        season,
        locale,
    })
    const standingsGroups = (data?.data || [])
    const showTableTitle = standingsGroups[0]?.groups?.length > 1

    const { t } = useCustomTranslation('common')
    const TABLE_LABELS = [
        t('match_table.played'),
        t('match_table.w'),
        t('match_table.d'),
        t('match_table.l'),
        [t('match_table.w'), t('match_table.d'), t('match_table.l')],
        t('match_table.goals'),
        t('match_table.points'),
        t('match_table.matches'),
    ]

    const isMobileColumn = !isInSidebar && blockWidth < 541

    return (
        <div
            className={clsx(
                styles.contentWrapper,
                isInSidebar && styles.isInSidebar,
                isInSidebar && blockWidth < 500 && styles.isWidthBelow500,
            )}
            ref={containerRef}
        >
            {isFetching && <LoaderTemp />}
            {!isFetching && (
                <>
                    {isInSidebar && (
                        <div className='flex flex-column grid--8'>
                            <div className='flex flex-align flex-justify grid--8 mt-12'>
                                <span className={st.title}>
                                    Таблица
                                </span>
                                <button
                                    className='button button--matchday-menu button--matchday-menu-top'
                                    onClick={() => {
                                        if (changePageTab) changePageTab('fixtures')
                                    }}
                                >
                                    <Image
                                        src={full_screen}
                                        alt=''
                                        width={16}
                                        height={16}
                                    />
                                </button>
                            </div>
                        </div>
                    )}
                    {standingsGroups.map((standingsGroup, groupIndex) =>
                        <Fragment key={groupIndex}>
                            {standingsGroup.groups.map((group, index) => (
                                <Fragment key={index}>
                                    <div className={clsx(styles.table, styles.tableTeams)}>
                                        {!isInSidebar && showTableTitle && (
                                            <div className={clsx(styles.tableTitleMobile, styles.isOnlyMobile)}>
                                                <span className={styles.tableTitle}>
                                                    {group.group_title}
                                                </span>
                                            </div>
                                        )}
                                        <div className={styles.tableHeader}>
                                            <span
                                                className={isMobileColumn ? styles.tableHeaderLabel : styles.tableTitle}
                                                style={{textAlign: 'left'}}
                                            >
                                                {isMobileColumn
                                                    ? t('match_table.team')
                                                    : showTableTitle && group.group_title
                                                }
                                            </span>
                                            {TABLE_LABELS.map((label, index) => (
                                                <span
                                                    className={styles.tableHeaderLabel}
                                                    key={index}
                                                >
                                                    {Array.isArray(label) ? (
                                                        label.map((i, index) => (
                                                            <span
                                                                className={styles.labelWithSlash}
                                                                key={index}
                                                            >
                                                                {i}
                                                            </span>
                                                        ))) : label
                                                    }
                                                </span>
                                            ))}
                                        </div>
                                        {group.table.map((row) => {
                                            const legend = standingsGroup.legend.find((i) => i.id === row.legend_id)
                                            return (
                                                <div
                                                    className={styles.tableRow}
                                                    key={row.team.id}
                                                >
                                                    <div className={styles.teamContainer}>
                                                        <Tooltip
                                                            content={legend?.tilte}
                                                            skipTooltip={!legend}
                                                        >
                                                            <div
                                                                className={styles.teamPosition}
                                                                style={{
                                                                    borderColor: legend
                                                                        ? '#' + legend.color
                                                                        : undefined,
                                                                }}
                                                            >
                                                                {row.position}
                                                            </div>
                                                        </Tooltip>
                                                        <Logo
                                                            className={styles.teamLogo}
                                                            type='teams'
                                                            size={36}
                                                            logoId={row.team.logo}
                                                            dimension={26}
                                                            withBorder
                                                        />
                                                        <TeamName
                                                            team={row.team}
                                                            highlight={row.highlight}
                                                        />
                                                    </div>
                                                    <span className='txt-align-center'>{row.data.played}</span>
                                                    <span className='txt-align-center'>{row.data.won}</span>
                                                    <span className='txt-align-center'>{row.data.draw}</span>
                                                    <span className='txt-align-center'>{row.data.lost}</span>
                                                    <span className='txt-align-center'>
                                                        <span className={styles.labelWithSlash}>
                                                            {row.data.won}
                                                        </span>
                                                        <span className={styles.labelWithSlash}>
                                                            {row.data.draw}
                                                        </span>
                                                        <span className={styles.labelWithSlash}>
                                                            {row.data.lost}
                                                        </span>
                                                    </span>
                                                    <span className='txt-align-center'>{row.data.goals}</span>
                                                    <span className='txt-align-center font-bold'>{row.data.points}</span>
                                                    <div className={styles.matchesResults}>
                                                        {row.last_matches.map((match, index) => (
                                                            <div
                                                                className={clsx(styles.matchLogo, getMatchResultColor(match))}
                                                                key={index}
                                                            >
                                                                {match.toUpperCase()}
                                                            </div>
                                                        ))}
                                                        {row.next_match ?
                                                            <Link href={row.next_match.url}>
                                                                <div
                                                                    className={clsx(styles.matchLogo, styles.nextMatch)}>
                                                                    <Logo
                                                                        type="teams"
                                                                        size={36}
                                                                        logoId={row.next_match.team.logo}
                                                                        dimension={12}
                                                                    />
                                                                </div>
                                                            </Link>
                                                            : <div className={clsx(styles.matchLogo)} />
                                                        }
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                    {!isInSidebar && group.matches.length > 0 && (
                                        <div className={styles.table}>
                                            <div className={styles.tableHeaderMatches}>
                                                <span className={styles.tableHeaderLabel}>1</span>
                                                <span className={styles.tableHeaderLabel}>X</span>
                                                <span className={styles.tableHeaderLabel}>2</span>
                                            </div>
                                            <StandingsMatchesCollapsible
                                                matches={group.matches}
                                                // Коэффициент - разница между высотами строк таблицы команд и кэфов.
                                                // Таблица кэфов не должна по высоте быть больше таблицы команд -> переключатель
                                                matchesShowInitial={Math.floor(group.table.length * 0.7)}
                                                className={styles.tableRowMatches}
                                            />
                                        </div>
                                    )}
                                </Fragment>
                            ))}
                            <StandingsLegend legend={standingsGroup.legend} />
                        </Fragment>
                    )}
                </>
            )}
        </div>
    )
}

function getMatchResultColor(matchResult: MatchResultCode) {
    return matchResult === 'w'
        ? styles.colorWin
        : matchResult === 'l'
            ? styles.colorLose
            : ''
}
