import React, { useRef, useState, useEffect, useMemo, forwardRef } from 'react'
import Image from 'next/image'
import Link from 'next/link'
import clsx from 'clsx';
import next_period from '../public/img/period.svg'
import help from '../public/img/help_ico.svg'
import moment from 'moment'
import NoSSR from './NoSsr'
import { useCustomTranslation } from '../hooks/useCustomTranslation'
import StatusBadge from './StatusBadge'
import MatchDayHeaderAction from './match/MatchDayHeaderAction'
import {Tooltip} from './Tooltip';
import {checkWinScore} from '../utils/match';
import {ElementAnimated} from './animation';
import { useLazyGetMatchQuery } from '@/api'

type Props = {
    matchday: any
    loading?: string
    type: string
    onChange?: Function
    full: boolean
    direct?: boolean
}

export default forwardRef(function MatchDay(
    { matchday, type, onChange, full }: Props,
    ref: any
) {
    const staticPath = 'https://i.oddsocean.com/img/logos/teams/'
    const { t } = useCustomTranslation('common')

    // live match
    const liveMatch = useMemo(() => matchday?.live, [matchday?.live])
    // upcoming
    const upcomingMatch = useMemo(
        () =>
            !matchday?.score &&
            moment.utc(new Date()) < moment(matchday?.date_start * 1000),
        [matchday?.score]
    )
    // show penalty block
    const penaltyBlock = useMemo(
        () => matchday?.penalty?.length > 0,
        [matchday?.penalty]
    )

    const matchScoreResults = matchday?.score?.results as any[] || []
    const scoresHidden = matchScoreResults.filter((i: any) => i.type == 'hidden')
    const scoreSmall = matchScoreResults.find((i) => i.type == 'small')

    const checkBets = (bets: any) => {
        let iterator = 0
        for (let i = 0; i < bets?.length; i++) {
            if (bets[i].percent > 0) {
                iterator++
                break
            }
        }
        return iterator > 0;
    }

    const checkBetsMemo = useMemo(() => {
        return checkBets(matchday?.maker?.odds)
    }, [matchday?.match_id])

    const content = useRef(null)

    // Show placeholders until teams logo are loaded
    const [imgsLoading, setImgsLoading] = useState({
        firstLoaded: false,
        secondLoaded: false,
    })
    useEffect(() => {
        setImgsLoading({firstLoaded: false, secondLoaded: false})
    }, [matchday?.match_id]);

    return (
        <div className='sidebar__wrapper-block' ref={ref}>
            <div
                className={clsx(
                    'block block--match-day',
                    !upcomingMatch && 'block--match-day--expanded',
                )}
            >
                {!upcomingMatch ? (
                    <div className='block--match-day--finished'>
                        <nav
                            aria-label='breadcrumb'
                            style={{ width: 'calc(100% - 64px)' }}
                        >
                            <ol
                                className='breadcrumb breadcrumb--block'
                                style={{
                                    display: 'block',
                                    flex: 1,
                                    minWidth: 0,
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                }}
                            >
                                {matchday?.competition?.map((item: any, index: number) => (
                                    <li
                                        className='breadcrumb-item'
                                        aria-current='page'
                                        key={index}
                                    >
                                        <Link
                                            className='breadcrumb__link'
                                            href={item?.url ? item?.url : '#'}
                                        >
                                            {item?.title}
                                        </Link>
                                    </li>
                                )
                                )}
                                {matchday?.stage && (
                                    <li
                                        className='breadcrumb-item breadcrumb-item-disabled'
                                        aria-current='page'
                                    >
                                        {matchday?.stage}
                                    </li>
                                )}
                            </ol>
                        </nav>
                        <MatchDayHeaderAction
                            matchId={matchday.match_id}
                            onChange={onChange}
                            full={full}
                            isLive={matchday?.live}
                        />
                    </div>
                ) : (
                    <div className='match-day-header flex flex-align flex-justify'>
                        <nav
                            aria-label='breadcrumb'
                            style={{ width: 'calc(100% - 64px)' }}
                        >
                            <ol className='breadcrumb breadcrumb--block breadcrumb__match'>
                                {matchday?.competition?.map(
                                    (item: any, index: number) => (
                                        <li
                                            key={`matchday-breadcrumb-${index}`}
                                            className='breadcrumb-item'
                                            aria-current='page'
                                        >
                                            <Link
                                                className='breadcrumb__link'
                                                href={item?.url || '#'}
                                            >
                                                {item?.title}
                                            </Link>
                                        </li>
                                    )
                                )}
                                {matchday?.stage && (
                                    <li
                                        className='breadcrumb-item breadcrumb-item-disabled'
                                        aria-current='page'
                                    >
                                        {matchday?.stage}
                                    </li>
                                )}
                            </ol>
                        </nav>
                        <MatchDayHeaderAction
                            matchId={matchday.match_id}
                            onChange={onChange}
                            full={full}
                            isLive={matchday?.live}
                        />
                    </div>
                )}
                <div className={`match-day ${full ? 'match-day--full' : ''}`}>
                    {upcomingMatch && (
                        <>
                            <h5 className='match-day__title'>
                                {type === 'matchday'
                                    ? t('match.match_of_the_day')
                                    : t('match.start')}
                            </h5>
                            <p className='match-day__subtitle'>
                                {t('match.date_start', {
                                    date: new Date(matchday?.date_start),
                                    format: 'match.calendar',
                                })}
                            </p>
                        </>
                    )}
                    {!upcomingMatch && (
                        <h5 className='match-day__date'>
                            {t(
                                moment(matchday?.date_start * 1000)
                                    .utc()
                                    .year() == moment(new Date()).utc().year()
                                    ? 'match.date_upcoming'
                                    : 'match.date_upcoming_year',
                                {date: new Date(matchday?.date_start)}
                            )}
                        </h5>
                    )}

                    <div className='match-day__content'>
                        <div className='match-day__left-content'>
                            <div className='match-day__avatar-wrapper'>
                                {matchday?.team1?.logo == '' ? (
                                    <div className='team-avatar-placeholder'>
                                        <Image
                                            src={help}
                                            alt='ven'
                                            width={16}
                                            height={16}
                                        />
                                    </div>
                                ) : (
                                    <>
                                        <Image
                                            src={`${staticPath}150x150/${matchday?.team1?.logo}.png`}
                                            alt={matchday?.team1.title}
                                            className='match-day__team-flag'
                                            width={68}
                                            height={68}
                                            onLoad={() => setImgsLoading((s => ({
                                                ...s,
                                                firstLoaded: true,
                                            })))}
                                        />
                                        {!imgsLoading.firstLoaded && (
                                            <div className='match-day__team-flag-loader'/>
                                        )}
                                    </>
                                )}
                                {matchday?.next_round == 1 && (
                                    <Tooltip content={t('match.next_round_qlf')}>
                                        <div className='match-day__next-period'>
                                            <Image
                                                src={next_period}
                                                alt='ven'
                                                className='match-day__nxt'
                                                width={16}
                                                height={16}
                                            />
                                        </div>
                                    </Tooltip>
                                )}
                            </div>
                            <p className='match-day__team-title'>
                                {matchday?.team1?.title}
                            </p>
                        </div>
                        <div className='match-day__center-content'>
                            {upcomingMatch && (
                                <div className='match-day__timestamp'>
                                    <h5 className='match-day__title'>
                                        {t('match.starts_in')}
                                    </h5>
                                    <TimerMatchStart
                                        matchId={matchday.match_id}
                                        dateStartInitial={matchday.date_start}
                                    />
                                    {matchday?.tip && (full
                                        ? (
                                            <a
                                                className='badge badge--main badge--match-day'
                                                href='#forecast-block'
                                            >
                                                {t('global.tip')}
                                            </a>
                                        )
                                        : (
                                            <Link
                                                className='badge badge--main badge--match-day'
                                                href={matchday.url + '#forecast-block'}
                                            >
                                                {t('global.tip')}
                                            </Link>
                                        )
                                    )}
                                </div>
                            )}
                            {!upcomingMatch && (
                                <>
                                    {matchday && (
                                        <StatusBadge match={matchday}/>
                                    )}

                                    <div className='match-day__score-wrapper'>
                                        <Tooltip
                                            content='Нет данных о счете'
                                            skipTooltip={matchday?.score?.result_short !== null}
                                        >
                                            <ElementAnimated
                                                value={(matchday?.score?.result_short ?? [])[0] as number ?? '-'}
                                                renderElement={(
                                                    ref,
                                                    value,
                                                    cls,
                                                ) => (
                                                    <div
                                                        ref={ref}
                                                        className={clsx('match-day__score', cls)}
                                                        title={String(value)}
                                                    >
                                                        {value}
                                                    </div>
                                                )}
                                                animation='animationMatchScore'
                                                animateCondition={liveMatch && checkWinScore(matchday?.score?.result_short, 0)}
                                            />
                                        </Tooltip>
                                        <div className='match-day__score-del'>
                                            :
                                        </div>
                                        <Tooltip
                                            content='Нет данных о счете'
                                            skipTooltip={matchday?.score?.result_short !== null}
                                        >
                                            <ElementAnimated
                                                value={(matchday?.score?.result_short ?? [])[1] as number ?? '-'}
                                                renderElement={(
                                                    ref,
                                                    value,
                                                    cls,
                                                ) => (
                                                    <div
                                                        ref={ref}
                                                        className={clsx('match-day__score', cls)}
                                                        title={String(value)}
                                                    >
                                                        {value}
                                                    </div>
                                                )}
                                                animation='animationMatchScore'
                                                animateCondition={liveMatch && checkWinScore(matchday?.score?.result_short, 1)}
                                            />
                                        </Tooltip>
                                    </div>
                                    {scoreSmall && (
                                        <div className='match-day__score-wrapper-small'>
                                            <p className='match-day__title-small-score'>
                                                {scoreSmall.title}
                                            </p>
                                            <div
                                                className='match-day__score-wrapper match-day__score-wrapper--small'>
                                                <div className='match-day__score match-day__score--small'>
                                                    <span>
                                                        {scoreSmall.team1}
                                                    </span>
                                                </div>
                                                <div className='match-day__score-del match-day__score-del--small'>
                                                    :
                                                </div>
                                                <div className='match-day__score match-day__score--small'>
                                                    <span>
                                                        {scoreSmall.team2}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </>
                            )}
                        </div>
                        <div className='match-day__right-content'>
                            <div className='match-day__avatar-wrapper'>
                                {matchday?.team2?.logo == '' ? (
                                    <div className='team-avatar-placeholder'>
                                        <Image
                                            src={help}
                                            alt='ven'
                                            width={16}
                                            height={16}
                                        />
                                    </div>
                                ) : (
                                    <>
                                        <Image
                                            src={`${staticPath}150x150/${matchday?.team2?.logo}.png`}
                                            alt={matchday?.team2.title}
                                            className='match-day__team-flag'
                                            width={68}
                                            height={68}
                                            onLoad={() => setImgsLoading((s => ({
                                                ...s,
                                                secondLoaded: true,
                                            })))}
                                        />
                                        {!imgsLoading.secondLoaded && (
                                            <div className='match-day__team-flag-loader'/>
                                        )}
                                    </>
                                )}

                                {matchday?.next_round == 2 && (
                                    <Tooltip content={t('match.next_round_qlf')}>
                                        <div className='match-day__next-period'>
                                            <Image
                                                src={next_period}
                                                alt='ven'
                                                className='match-day__nxt'
                                                width={16}
                                                height={16}
                                            />
                                        </div>
                                    </Tooltip>
                                )}
                            </div>
                            <p className='match-day__team-title'>
                                {matchday?.team2?.title}
                            </p>
                        </div>
                    </div>

                    {/*@todo Remove later*/}
                    {/*{expandedInfo && (*/}
                    {/*    <div*/}
                    {/*        onClick={onToggleExpanded}*/}
                    {/*        className={`match-day__all-bets match-day__all-bets--add ${*/}
                    {/*            expanded ? 'match-day__all-bets--expanded' : ''*/}
                    {/*        }`}*/}
                    {/*    >*/}
                    {/*        <div className='match-day__line match-day__line--add-info'></div>*/}
                    {/*        <p className='match-day__title match-day__title--all'>*/}
                    {/*            {t('match.score_details')}{' '}*/}
                    {/*            <Image*/}
                    {/*                src={arrow_first}*/}
                    {/*                className='dropdown-matches__ico'*/}
                    {/*                width={12}*/}
                    {/*                height={12}*/}
                    {/*                alt='arrow-first'*/}
                    {/*            />*/}
                    {/*        </p>*/}
                    {/*        <div className='match-day__line match-day__line--add-info'></div>*/}
                    {/*    </div>*/}
                    {/*)}*/}

                    <div
                        className='match-day__expanded-info mt-16'
                        ref={content}
                    >
                        {scoresHidden.length > 0 && (
                            <div className='match-day__expanded-score-wrapper grid grid--8'>
                                {scoresHidden.map(
                                    (item: any, index: number) => (
                                        <div
                                            className='match-day__expanded-score'
                                            key={`hidden-score-${index}`}
                                        >
                                            <p className='match-day__expanded-score-title'>
                                                {item.title}
                                            </p>
                                            <div
                                                className='match-day__score-wrapper match-day__score-wrapper--small match-day__score-wrapper--white'>
                                                <div className='match-day__score match-day__score--small'>
                                                    <span>{item.team1}</span>
                                                </div>
                                                <div className='match-day__score-del match-day__score-del--small'>
                                                    :
                                                </div>
                                                <div className='match-day__score match-day__score--small'>
                                                    <span>{item.team2}</span>
                                                </div>
                                            </div>
                                        </div>
                                    ),
                                )}
                            </div>
                        )}

                        {penaltyBlock && (
                            <div className='penalty-wrapper'>
                                <div className='penalty-wrapper__row'>
                                    <div className='penalty-wrapper__header'>
                                        <div className='penalty-wrapper__left flex flex-align grid grid--8'>
                                            <Image
                                                src={`${staticPath}36x36/${matchday?.team1?.logo}.png`}
                                                alt='ven'
                                                className='match-day__team-flag'
                                                width={20}
                                                height={20}
                                            />
                                            <span className='penalty-wrapper__header-title'>
                                                {matchday?.team1?.title}
                                            </span>
                                        </div>
                                        <div className='penalty-wrapper__right'>
                                            <div className='penalty-wrapper__goal-wrapper'>
                                                {matchday?.penalty?.[0]?.map(
                                                    (
                                                        pen: any,
                                                        index: number,
                                                    ) => (
                                                        <div
                                                            key={`player-goal-0-${index}`}
                                                            className={`penalty-wrapper__goal-circle ${
                                                                !pen.scores
                                                                    ? 'penalty-wrapper__goal-circle--red'
                                                                    : ''
                                                            }`}
                                                        ></div>
                                                    )
                                                )}
                                            </div>
                                            <span className='penalty-wrapper__count-goal'>
                                                {
                                                    matchday?.penalty?.[0]?.filter(
                                                        (pen: any) =>
                                                            pen.scores
                                                    ).length
                                                }
                                            </span>
                                        </div>
                                    </div>
                                    <div className='penalty-wrapper__content'>
                                        {matchday?.penalty?.[0]?.map(
                                            (pen: any, index: number) => (
                                                <div
                                                    key={`player-content-0-${index}`}
                                                    className='penalty-wrapper__player'
                                                >
                                                    <div
                                                        className={`penalty-wrapper__goal-circle ${
                                                            !pen.scores
                                                                ? 'penalty-wrapper__goal-circle--red'
                                                                : ''
                                                        }`}
                                                    ></div>
                                                    <span
                                                        className={`penalty-wrapper__player-title ${
                                                            !pen.scores
                                                                ? 'penalty-wrapper__player-title--goal'
                                                                : ''
                                                        }`}
                                                    >
                                                        {pen.player}
                                                    </span>
                                                </div>
                                            )
                                        )}
                                    </div>
                                </div>
                                <div className='penalty-wrapper__row penalty-wrapper__row--last'>
                                    <div className='penalty-wrapper__header'>
                                        <div className='penalty-wrapper__left flex flex-align grid grid--8'>
                                            <Image
                                                src={`${staticPath}36x36/${matchday?.team2?.logo}.png`}
                                                alt='ven'
                                                className='match-day__team-flag'
                                                width={20}
                                                height={20}
                                            />
                                            <span className='penalty-wrapper__header-title'>
                                                {matchday?.team2?.title}
                                            </span>
                                        </div>
                                        <div className='penalty-wrapper__right'>
                                            <div className='penalty-wrapper__goal-wrapper'>
                                                {matchday?.penalty?.[1]?.map(
                                                    (
                                                        pen: any,
                                                        index: any
                                                    ) => (
                                                        <div
                                                            key={`player-goal-1-${index}`}
                                                            className={`penalty-wrapper__goal-circle ${
                                                                !pen.scores
                                                                    ? 'penalty-wrapper__goal-circle--red'
                                                                    : ''
                                                            }`}
                                                        ></div>
                                                    )
                                                )}
                                            </div>
                                            <span className='penalty-wrapper__count-goal'>
                                                {
                                                    matchday?.penalty?.[1]?.filter(
                                                        (pen: any) =>
                                                            pen.scores
                                                    ).length
                                                }
                                            </span>
                                        </div>
                                    </div>
                                    <div className='penalty-wrapper__content'>
                                        {matchday?.penalty?.[1]?.map(
                                            (pen: any, index: number) => (
                                                <div
                                                    key={`player-content-1-${index}`}
                                                    className='penalty-wrapper__player'
                                                >
                                                    <div
                                                        className={`penalty-wrapper__goal-circle ${
                                                            !pen.scores
                                                                ? 'penalty-wrapper__goal-circle--red'
                                                                : ''
                                                        }`}
                                                    ></div>
                                                    <span
                                                        className={`penalty-wrapper__player-title ${
                                                            !pen.scores
                                                                ? 'penalty-wrapper__player-title--goal'
                                                                : ''
                                                        }`}
                                                    >
                                                        {pen.player}
                                                    </span>
                                                </div>
                                            )
                                        )}
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                    {matchday?.maker && (
                        <>
                            <div className='match-day__all-bets'>
                                <div className='match-day__line'></div>
                                <p className='match-day__title match-day__title--all'>
                                    {t('global.betting_contest')}
                                    {liveMatch && (
                                        <span className='match-day__badge-title match-day__badge-title--live'>
                                            LIVE
                                        </span>
                                    )}
                                </p>
                                <div className='match-day__line'></div>
                            </div>
                            <div className='match-day__bets grid grid--8'>
                                {matchday.maker.odds.map(
                                    (item: any, index: number) => (
                                        <div
                                            key={`matchday-bet-${index}`}
                                            className='match-day__wrapper-bet'
                                        >
                                            <div className='match-day__type-bet'>
                                                <span>
                                                    {item.odd_select_display}
                                                </span>
                                            </div>
                                            <Tooltip content={t('global.to_betslip')}>
                                                <div
                                                    className={`match-day__bet ${
                                                        liveMatch
                                                            ? 'match-day__bet--live'
                                                            : ''
                                                    } ${
                                                        !checkBetsMemo
                                                            ? 'match-day__bet--center'
                                                            : ''
                                                    }`}
                                                >
                                                    <div className='match-day__bet-left'>
                                                        <span>
                                                            {item?.display
                                                                ? item?.display
                                                                : '-'}
                                                        </span>
                                                    </div>
                                                    {checkBetsMemo &&
                                                        !liveMatch && (
                                                        <div className='match-day__bet-badge'>
                                                            <span>
                                                                {item?.percent}%
                                                            </span>
                                                        </div>
                                                    )}
                                                </div>
                                            </Tooltip>
                                        </div>
                                    )
                                )}
                            </div>
                        </>
                    )}
                </div>
            </div>

            {!full && type === 'matchday' && (
                <button
                    className='button button--matchday-link'
                    onClick={() => onChange(true)}
                >
                    {t('match.main_details')}
                </button>
            )}
        </div>
    )
})

type TimerMatchStartProps = {
    matchId: number,
    dateStartInitial: number,
}

function TimerMatchStart({
    matchId,
    dateStartInitial,
}: TimerMatchStartProps) {
    const [matchQueryTrigger] = useLazyGetMatchQuery(undefined)

    const { t, i18n } = useCustomTranslation('common')
    const [dateNow, setDateNow] = useState(new Date())
    const refTimer = useRef(null)

    const matchDateStart = new Date(dateStartInitial * 1000)

    useEffect(() => {
        if (dateNow >= matchDateStart) {
            if (refTimer.current) clearTimeout(refTimer.current)
            matchQueryTrigger({
                id: matchId,
                locale: i18n.language,
            })
            return
        }

        refTimer.current = setTimeout(() => setDateNow(new Date()), 1000)
        return () => {
            clearTimeout(refTimer.current)
        }
    }, [dateNow])

    return (
        <NoSSR>
            <p className='match-day__subtitle'>
                {t('match.timer', {
                    date: matchDateStart,
                    dateNow: dateNow,
                })}
            </p>
        </NoSSR>
    )
}