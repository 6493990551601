import Image from 'next/image'
import { useRouter } from 'next/router'
import { useSelector } from 'react-redux'
import React, { useState, useRef, useEffect, useMemo } from 'react'
import { useTranslation } from 'next-i18next'
import { getCountries } from '../../api/competitionsApi'
import Head from 'next/head'
import { useAppDispatch, useAppSelector, useRouteIsChanging } from '../../hooks'
import football from '../../public/img/football.svg'
import Dropdown from '../../components/Dropdown'
import {
    selectRoutePrev,
} from '../../features/app'
import PreloadCenterContent from '../../components/PreloaderCenterContent'
import Sidebar from '../../components/Sidebar'
import {
    getMatches,
    getTopCompetitions,
    matchesApi,
    useGetMatchesQuery,
    useUpdateCompetitionMatchesMutation,
} from '../../api'
import HeaderDropdown from '../../components/HeaderDropdown'
import MatchesBlockWithFavorite from '../../components/MatchesBlock'
import dynamic from 'next/dynamic'
import MatchesNotFound from '../../components/MatchesNotFound'
import {isRequestFromBot, MatchesTimePeriodValue} from '../../api';
import clsx from 'clsx';
import {Calendar, FilterMatchesType} from '../../components/filters';
import {
    selectFilterDate,
    selectFilterTime,
    selectFilterMatchesType,
    setFilterDate, setFilterTime,
    setFilterMatchesType,
    enableHydration,
} from '../../features/filters'
import { getDateFormatted, getUniqueId, scrollToWindowTop } from '../../utils'
import {MatchDetailBlocks} from '../../components/match-details';
import { APP_ROUTES } from '../../const'
import { selectMatchId, setMatchCloseFallback, setMatchId, setMatchIdReset } from '../../features/match'

export interface Option {
    value: string
    title: string
    ico?: any
}

const CountriesBlock = dynamic(() => import('../../components/CountriesBlock'), {
    ssr: true,
    loading: () => <div className='preloader' />,
})

function isHomePageFiltersUrl(urlDateOrTime: string) {
    return urlDateOrTime &&  (urlDateOrTime.split('-').length === 3 || urlDateOrTime.includes('next'))
}

export const HomePage = (_props: any) => {
    /** Используем отдельную обертку над компонентом страницы для контроля сброса стейта.
     * При переходе на главную страницу (url '/'), ее нужно 'мягко перезагрузить' через сброс локального стейта,
     * как будто сайт был открыт в первый раз. Перезагрузка происходит за счет смены ключа компонента страницы,
     * ключ генерируется на сервере каждый раз при переходе на url '/'.
     * */
    const [pageKey, setPageKey] = useState('1')
    const refIsMounted = useRef(false)

    useEffect(() => {
        if (!_props.pageKey || !refIsMounted.current) return

        setPageKey(_props.pageKey)
    }, [_props.pageKey])

    useEffect(() => {
        refIsMounted.current = true
        return () => {
            refIsMounted.current = false
        }
    }, [])

    return (
        <HomePageEntry
            {..._props}
            key={pageKey}
        />
    )
}

function HomePageEntry(_props: any) {
    const parentRef = useRef(null);
    const { t } = useTranslation('common')
    const { i18n } = useTranslation()
    const router = useRouter()
    const dispatch = useAppDispatch()

    const routePrev = useSelector(selectRoutePrev)
    const full = useAppSelector((state) => state.app.full)

    /** Matches filters */

    const matchesDate = useSelector(selectFilterDate)
    const matchesTime = useSelector(selectFilterTime)
    const matchesType = useSelector(selectFilterMatchesType)

    const refIsMounted = useRef(false)
    const refIsRouteChanging = useRouteIsChanging()

    // On each filer update -> update page url
    // On sidebar close -> restore filters in ulr
    useEffect(() => {
        if (!refIsMounted.current || refIsRouteChanging.current) return

        const newRoute = `/football/${matchesTime || matchesDate}${matchesType !== 'all' ? '/' + matchesType : ''}`

        if (newRoute === router.asPath) return

        router.push(
            newRoute,
            undefined,
            {
                locale: i18n.language,
                shallow: true,
                scroll: true,
            }
        )
    }, [matchesDate, matchesTime, matchesType]);

    // On each url change -> try to update filters
    useEffect(() => {
        if (!refIsMounted.current) return

        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [urlSport, urlDateOrTime, urlMatchType] = router.asPath.slice(1).split('/')

        // Check if new url belongs to index page with filters
        if (!isHomePageFiltersUrl(urlDateOrTime)) return

        const isUrlDateFilter = !urlDateOrTime || !urlDateOrTime.includes('next')

        if (isUrlDateFilter && urlDateOrTime !== matchesDate) {
            dispatch(setFilterDate(urlDateOrTime ?? getDateFormatted()))
        }
        // Reset time filter, if date is in url
        if (isUrlDateFilter && matchesTime) {
            dispatch(setFilterTime(null))
        }
        if (!isUrlDateFilter && urlDateOrTime !== matchesTime) {
            dispatch(setFilterTime(urlDateOrTime))
        }
        if (urlMatchType !== matchesType) {
            dispatch(setFilterMatchesType(urlMatchType ?? 'all'))
        }
    }, [routePrev])

    useEffect(() => {
        refIsMounted.current = true
        return () => {
            refIsMounted.current = false
        }
    }, []);

    /** Get matches list */

    const [openedCompetitions, setCompetitions] = useState([])
    const [oddType, setOddType] = useState('3w')
    const [sport] = useState('1')
    const [matchesPollingInterval, setMatchesPollingInterval] = useState(0)

    const matchesQuery = useGetMatchesQuery(
        {
            sport: sport,
            oddType: oddType,
            type: matchesType,
            date: matchesDate,
            timePeriod: matchesTime,
            locale: i18n.language,
            competitions: openedCompetitions,
        },
        {
            skip: false,
            pollingInterval: full ? 0 : matchesPollingInterval,
        }
    )

    const [updateCompetitionMatches] = useUpdateCompetitionMatchesMutation()

    useEffect(() => {
        if (matchesQuery.data?.update) setMatchesPollingInterval(matchesQuery.data.update * 1000)
    }, [matchesQuery.data?.update]);

    /** Use loading state, as matchesQuery.isFetching always active <- data polling */

    const [areMatchesLoading, setAreMatchesLoading] = useState(false)

    useEffect(() => {
        setAreMatchesLoading(true)
    }, [oddType, matchesType, matchesDate, matchesTime, i18n.language]);

    useEffect(() => {
        if (!matchesQuery.isFetching) setAreMatchesLoading(false)
    }, [matchesQuery.isFetching])

    /** Get selected match data */

    const matchId = useSelector(selectMatchId)

    const oddTypes = useMemo(() => {
        const list: Option[] = []
        matchesQuery.data?.data.odds_types.map((type: any) => {
            list.push({ value: type.type, title: type.title })
        })
        return list as Option[]
    }, [matchesQuery.data?.data.odds_types])

    const liveMatchesAmount = matchesQuery.data?.data.live_count || 0

    // select match from main table
    function selectMatch(id: string, live: boolean, url: string) {
        if (window.innerWidth <= 1150) {
            router.push(
                router.locale + url,
                undefined,
                {
                    locale: router.locale,
                    shallow: false,
                })
            return
        }

        dispatch(setMatchId(Number(id)))
        scrollToWindowTop()
    }

    const odds_selects = useMemo(
        () => matchesQuery.data?.data.odds_selects,
        [matchesQuery.data?.data.competitions]
    )

    async function _loadCompetion(id: string) {
        if (openedCompetitions.includes(id)) {
            const updatedItems = openedCompetitions.filter(
                (existingItem) => existingItem !== id
            )
            setCompetitions(updatedItems)
        } else {
            setCompetitions([...openedCompetitions, id])
        }
        await updateCompetitionMatches({
            sport: sport,
            oddType: oddType,
            type: matchesType,
            date: matchesDate,
            competitionId: id,
            locale: i18n.language,
            competitions: openedCompetitions,
        })
    }

    return (
        <div className='flex grid grid--12 container container--mobile'>
            <Sidebar side='left'>
                <CountriesBlock />
            </Sidebar>
            <div className={clsx('layout-main-with-sidebar', full && 'layout-main-with-sidebar--full')}>
                <div className={clsx('content__main', full && 'content__main--hidden')}>
                    <div className='submenu submenu--content-filter'>
                        <Calendar />
                        <FilterMatchesType liveMatchesAmount={liveMatchesAmount} />
                    </div>
                    <div className='wrapper' ref={parentRef}>
                        {areMatchesLoading && <PreloadCenterContent />}
                        {matchesQuery.data && !areMatchesLoading && (
                            <>
                                <div className='content-header flex'>
                                    <div className='flex flex-align grid grid--12'>
                                        <Image
                                            src={football}
                                            alt='football'
                                            width={20}
                                            height={20}
                                            className={'content-header__ico'}
                                        />
                                        <h4 className='content-header__title show-desktop'>
                                            {t('main.football_bets_long')}
                                        </h4>
                                        <h4 className='content-header__title show-mobile'>
                                            {t('main.football_bets')}
                                        </h4>
                                    </div>
                                    <div className='dropdown-wrapper'>
                                        <Dropdown
                                            value={oddType}
                                            options={oddTypes}
                                            onChange={(e: any) => {
                                                setOddType(e.value)
                                            }}
                                            header={<HeaderDropdown />}
                                        />
                                    </div>
                                </div>
                            </>
                        )}
                        {!areMatchesLoading && matchesQuery.data?.data.competitions.length == 0 && (
                            <MatchesNotFound />
                        )}
                        {!areMatchesLoading && matchesQuery.data?.data.competitions.map(
                            (competition: any, index: number) => (
                                <MatchesBlockWithFavorite
                                    onClickMatch={(
                                        matchId: string,
                                        live: boolean,
                                        url: string,
                                    ) => selectMatch(matchId, live, url)}
                                    competition={competition}
                                    key={competition.id}
                                    index={index}
                                    matchesType={matchesType}
                                    odds_selects={odds_selects}
                                    onClickCompetition={_loadCompetion}
                                    oddType={oddType}
                                />
                            )
                        )}
                    </div>
                </div>
                <MatchDetailBlocks
                    key={matchId}
                    matchId={matchId}
                    isBlockFullSize={full}
                />
            </div>
        </div>
    )
}

HomePage.getSSRProps = (async (store: any, context: any) => {
    // Init matches list filters (get values from page url)
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [sport, date, matchesType]: Array<string | undefined> = context.query.slug ?? []

    store.dispatch(enableHydration())
    if (date && date.includes('next')) {
        store.dispatch(setFilterTime(date))
    } else {
        store.dispatch(setFilterDate(date ?? ''))
        store.dispatch(setFilterTime(null))
    }
    store.dispatch(setFilterMatchesType(matchesType ?? 'all'))

    if (isRequestFromBot(context)) {
        const requestPromses = Promise.all([
            store.dispatch(
                getMatches.initiate({
                    sport: '1',
                    oddType: '3w',
                    type: matchesType ?? 'all',
                    date: date ?? getDateFormatted(),
                    locale: context.locale,
                    competitions: [],
                    timePeriod: date && !date.includes('-') ? date as MatchesTimePeriodValue : null,
                })
            ),
            store.dispatch(
                getTopCompetitions.initiate({ locale: context.locale })
            ),
            store.dispatch(
                getCountries.initiate({ locale: context.locale })
            ),
        ])
        const storePromises = Promise.all(
            store.dispatch(matchesApi.util.getRunningQueriesThunk())
        )
        await Promise.all([requestPromses, storePromises])
    }

    // При переходе на главную (url '/') сбрасываем состояние страницы
    const isRouteMain = context.resolvedUrl === APP_ROUTES.MAIN

    store.dispatch(setMatchCloseFallback('main'))
    if (isRouteMain) store.dispatch(setMatchIdReset(true))

    return {
        pageKey: isRouteMain
            ? getUniqueId()
            : '',
    }
})
